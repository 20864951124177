import React from "react";
import * as moment from "moment";

interface DateDisplayProps {
  date: number;
  format?: string;
}

export const DateDisplay = ({ date, format }: DateDisplayProps) => {
  let theDate = moment.utc(date * 1000);
  if (!theDate.isValid()) {
    theDate = moment.utc(date);
  }
  const formatString = format || "dddd, MMMM D, YYYY";
  return <>{theDate.format(formatString)}</>;
};
